import { graphql } from "gatsby";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import PageLayout from "../components/PageLayout";
import {
  TITLE_FONTSIZE,
  SUBTITLE_FONTSIZE,
  TEXT_FONTSIZE,
} from "../utilities/constants";

const MyContainer = styled.div`
  padding: 180px 130px;
  background: url("/images/career.png") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media (max-width:600px){
    padding:180px 80px
  }
  @media (max-width:462px){
    padding:180px 50px
  }
`;

const Title = styled.div`
  font-size: ${TITLE_FONTSIZE};
  color: white;
`;
const SubTitle = styled.div`
  font-size: ${SUBTITLE_FONTSIZE};
  color: white;
`;
const Text = styled.div`
  width: 70vw;
  font-size: ${TEXT_FONTSIZE};
  font-weight: 300;
  color: rgb(180, 180, 180);
`;

const TEXT_CONTENT = [
  "Send us a <b>cover letter</b> along with your <b>resume (curriculum vitae)</b> at hr@gophonebox.com.",
  "Our HR team will contact you if you're selected.",
];

const Careers = () => {
  return (
    <PageLayout backgroundColor="#f2fafc" containerPadding="0px">
      <title>Join Us!</title>
      <MyContainer>
        <Title>
          <Trans>Careers</Trans>
        </Title>
        <SubTitle>
          <Trans>Join Us!</Trans>
        </SubTitle>
        {TEXT_CONTENT.map((t, i) => (
          <Text className="text-white" key={i}>
            <Trans
              defaults={t}
              components={{
                b: <b/>
              }}
            />
          </Text>
        ))}
      </MyContainer>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Careers;